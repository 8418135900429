















































import {Component, Prop, Vue} from 'vue-property-decorator';
import Loader from "@/components/Loader.vue";
@Component({
  components: { Loader },
})

export default class ContactFormComponent extends Vue {
  @Prop({default: ''}) private mailToAddr!: string;
  @Prop({default: ''}) private subject!: string;
  @Prop({default: true}) private isLoading!: boolean;
  @Prop({default: false}) private emailSent!: boolean;
  @Prop({default: []}) private errors!: string[];

  @Prop({default: ''}) private name!: string;
  @Prop({default: ''}) private street?: string;
  @Prop({default: ''}) private city?: string;
  @Prop({default: ''}) private country?: string;
  @Prop({default: ''}) private postcode?: string;
  @Prop({default: ''}) private telephone?: string;
  @Prop({default: ''}) private email!: string;
  @Prop({default: ''}) private remark!: string;

  private mailTemplate = {
    recipient: this.mailToAddr,
    subject: this.subject,
    body: '',
  }

  private handleClick(e: Event) {
    e.preventDefault();
    this.validateForm();
    if(this.errors.length < 1) {
      if(this.generateMailFromForm()) {
        this.redirectToMailApp();
        this.emailSent = true;
      };
    }
  }

  private generateMailFromForm(): boolean {
    var htmlBody: string = "";
    if(this.name) { htmlBody = htmlBody + this.$t('fields.name') + ': ' + this.name + '\n'};
    if(this.street) { htmlBody = htmlBody + this.$t('fields.street') + ': ' + this.street + '\n'};
    if(this.postcode) { htmlBody = htmlBody + this.$t('fields.postcode') + ': ' + this.postcode + '\n'};
    if(this.city) { htmlBody = htmlBody + this.$t('fields.city') + ': ' + this.city + '\n'};
    if(this.country) { htmlBody = htmlBody + this.$t('fields.country') + ': ' + this.country + '\n'};
    if(this.telephone) { htmlBody = htmlBody + this.$t('fields.telephone') + ': ' + this.telephone + '\n'};
    if(this.email) { htmlBody = htmlBody + this.$t('fields.email') + ': ' + this.email + '\n'};
    if(this.remark) { htmlBody = htmlBody + this.$t('fields.remark') + ': ' + this.remark + '\n'};

    if(!htmlBody) {
      return false;
    }
    this.mailTemplate.body = htmlBody;
    return true;

  }

  private clearForm() {
    this.name = '';
    this.street = '';
    this.postcode = '';
    this.city = '';
    this.country = '';
    this.telephone = '';
    this.email = '';
    this.remark = '';
  }

  private validateEmail(): boolean{
    let reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return reg.test(this.email);
  }
  private redirectToMailApp() {
    if(!this.mailToAddr) {
      let errMsg = this.$t('email.error') as string;
      this.errors.push(errMsg);
      return;
    }

    let mailToLink = this.mailTemplate.recipient;
    let subject = this.mailTemplate.subject;
    let body = encodeURIComponent(this.mailTemplate.body);
    let tempLink = document.createElement("a");
    tempLink.href = `mailto:${mailToLink}?subject=${subject}&body=${body}`;
    tempLink.click();
    this.clearForm();
  }

  private validateForm() {
    this.errors = [];
    let errMsg = "";
    if(!this.name) {
      errMsg = this.$t('email.error.name') as string;
      this.errors.push(errMsg);
    };
    if(!this.email) {
      errMsg = this.$t('email.error.email') as string;
      this.errors.push(errMsg);
    } else if (!this.validateEmail()) {
      errMsg = this.$t('email.error.validEmail') as string;
      this.errors.push(errMsg);
    }
    if(!this.remark) {
      errMsg = this.$t('email.error.remark') as string;
      this.errors.push(errMsg);
    }

  }

  mounted() {
    this.isLoading = false;
  }
}
