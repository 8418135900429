/**
 * A shared cache between all the EmbedConfigs for addScript calls,
 * to make sure we only add one script tag per script src.
 *
 * If we do not have an outstanding request to add this script to the page;
 * - create an entry in this cache with the initial resolve method of the current promise.
 *
 * If we already have an outstanding request to add this script to the page;
 *  - add this promise to it's collection in the cache.
 *
 * If the script loaded;
 * - resolve all the promises,
 *
 * This will only prevent duplicate requests the {@link loadApi} implementation should decide when the API did load.
 */
const SCRIPT_CACHE: {
    [name: string]: ExtractedPromise[];
} = {};

interface ExtractedPromise {
    resolve: (value?: void | PromiseLike<void>) => void;
    reject: (reason?: string) => void;
}

export interface Handlers {
    play: () => void;
    pause: () => void;
}

export abstract class EmbedConfig {

    public static isMatch(url: string): boolean {
        const m = url.match(this.matcher);
        return m != null && m.length > 1;
    }

    protected static matcher: RegExp;
    protected abstract idPosition: number;
    protected abstract api: string;
    protected handlers = {} as Handlers;
    /* tslint:disable variable-name */
    protected _id = '';
    /* tslint:enable variable-name */

    /**
     * These methods are used to proxy the implemented players' play actions,
     * so that the interface is shared between different video providers.
     *
     * @see EmbedConfig#play()
     * @see EmbedConfig#pause()
     */
    protected actions = {} as Handlers;

    protected constructor(protected url: string) {
        this.resetActionsAndHandlers();
    }

    get id() {
        return this._id;
    }



    public on(event: 'play' | 'pause', handler: () => void) {
        this.handlers[event] = handler;
    }

    public abstract initPlayer(target: HTMLElement, autoplay: boolean): Promise<any>;

    public abstract getThumb(): Promise<string>;

    public play(): void {
        return this.actions.play();
    }

    public pause(): void {
        return this.actions.pause();
    }

    protected extractId(matcher: RegExp) {
        const match = this.url.match(matcher);
        if (match) {
            this._id = match[this.idPosition];
        }
    }

    protected addScript(didLoad: () => boolean, appendWhere: HTMLElement = document.body) {
        return new Promise<void>((resolve, reject) => {
            if (didLoad()) {
                resolve();
                return;
            }

            const extractedPromise: ExtractedPromise = {resolve, reject};

            if (SCRIPT_CACHE[this.api]) {
                SCRIPT_CACHE[this.api].push(extractedPromise);
                return;
            }

            SCRIPT_CACHE[this.api] = [extractedPromise];

            const scriptElement: HTMLScriptElement = document.createElement('script');

            scriptElement.src = this.api;
            scriptElement.addEventListener('load', () => {
                SCRIPT_CACHE[this.api].forEach((value: ExtractedPromise) => {
                    if (didLoad()) {
                        value.resolve();
                    } else {
                        value.reject(`Loaded script (${this.api}) but didLoad() method returned false.`);
                    }
                });
            });

            scriptElement.addEventListener('error', () => {
                SCRIPT_CACHE[this.api].forEach((value: ExtractedPromise) => {
                    value.reject(`Error loading script (${this.api}).`);
                });
            });

            appendWhere.appendChild(scriptElement);
        });
    }

    protected abstract loadApi(): Promise<any>;

    private resetActionsAndHandlers() {
        const defaultFunctions = {} as Handlers;

        this.handlers = Object.assign({}, defaultFunctions);
        this.actions = Object.assign({}, defaultFunctions);
    }
}
