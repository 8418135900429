import process from '@/utils/propertyFileProcessor'
import Vue from 'vue';
import VueI18n from 'vue-i18n'

// @ts-ignore
import enProperties from '../../i18n/module-werkenbij-frontend_en.properties';

// @ts-ignore
import nlProperties from '../../i18n/module-werkenbij-frontend_nl.properties';

const en = process(enProperties);
const nl = process(nlProperties);

Vue.use(VueI18n);

const i18n = new VueI18n({
    locale: _mgnl.language,
    fallbackLocale: 'nl',
    messages: {
        en,
        nl,
    },
});

export default i18n;