import axios from "axios";

require("./scss/theme.scss");
import 'bootstrap/js/src/dropdown';
import 'bootstrap/js/src/button';
import 'bootstrap/js/src/collapse';
import Vue from 'vue';
import i18n from '@/i18n-setup';

import VideoComponent from "@/components/VideoComponent.vue";
import NewsOverviewComponent from "@/components/NewsOverviewComponent.vue";
import NewsCardComponent from "@/components/NewsCardComponent.vue";
import ContactFormComponent from "@/components/ContactFormComponent.vue";

Vue.config.productionTip = false;
Vue.config.devtools = true;


new Vue({
	i18n,
	comments: true,
	components: {
		VideoComponent,
		NewsOverviewComponent,
		NewsCardComponent,
		ContactFormComponent,
	},
	created() {
		console.debug("Vue has been initialized");
	}
}).$mount("#app");