<template>
  <div class="loader_container">
    <div class="loader"><div></div><div></div><div></div><div></div></div>
  </div>
</template>

<script>
export default {
  name: "Loader"
}
</script>

<style scoped>

</style>