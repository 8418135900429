import {EmbedConfig} from './embedConfig';
import getThumbnail from './youtube/getThumbnail';

const ALL_YOUTUBE_EMBED_RESOLVES: Array<(value?: any) => void> = [];
let YT_IFRAME_API_READY = false;

declare global {
    interface Window {
        YT: any;
        onYouTubeIframeAPIReady: any;
    }
}

export default class YoutubeEmbedConfig extends EmbedConfig {
    protected static matcher = /(youtube\.com|youtu\.be|youtube-nocookie\.com)\/(watch\?(.*&)?v=|v\/|u\/|embed\/?)?(videoseries\?list=(.*)|[\w-]{11}|\?listType=(.*)&list=(.*))(.*)/i;
    protected idPosition = 4;
    protected api = 'https://www.youtube.com/iframe_api';

    constructor(protected url: string) {
        super(url);
        this.extractId(YoutubeEmbedConfig.matcher);
    }

    public getThumb(): Promise<string> {
        return getThumbnail(this.id);
    }

    public initPlayer(target: HTMLElement, autoplay: boolean = false): Promise<any> {
        const detectionSpeed = 100;
        const pauseDebounce = 100;
        let pauseTimeout = 0;
        let prevTime = -1;
        let playerOptions = {};

        if(autoplay) {
            playerOptions = {
                fs: 0,
                modestbranding: 1,
                loop: 1,
            };
        };


        // YouTube videos trigger the PAUSED state when scrubbing, making scrubbing impossible.
        const onPlayerReady = (event: { target: any }) => {

            if (autoplay) {
                event.target.mute();
                event.target.playVideo();
            };

            window.setInterval(() => {
                const currentTime = event.target.getCurrentTime();
                // If the time changes within a margin of 0.5 any time after pausing, assume the user is scrubbing
                if (Math.abs(currentTime - prevTime) > 0.5) {
                    clearTimeout(pauseTimeout);
                }
                prevTime = currentTime;
            }, detectionSpeed);
        };

        const onPlayerStateChange = (event: { target: any; data: number }) => {
            const {ENDED, PLAYING, PAUSED} = window.YT.PlayerState;
            clearTimeout(pauseTimeout);
            switch (event.data) {
                case PLAYING:
                    this.handlers.play();
                    break;
                case PAUSED:
                    pauseTimeout = window.setTimeout(() => this.handlers.pause(), pauseDebounce);
                    break;
                case ENDED:
                    this.handlers.pause();
                    break;
                default:
                    break;
            }
        };


        return new Promise<any>((resolve) => {
            this.loadApi().then(() => {


                const player = new window.YT.Player(target, {
                    videoId: this.id,
                    rel: 0,
                    host: 'https://www.youtube-nocookie.com',
                    events: {
                        onReady: onPlayerReady,
                        onStateChange: onPlayerStateChange,
                    },
                    playerVars: playerOptions,
                });

                this.actions.play = () => player.playVideo();
                this.actions.pause = () => player.pauseVideo();

                resolve(player);
            });
        });
    }

    protected loadApi(): Promise<any> {
        return new Promise<any>((resolve) => {
            ALL_YOUTUBE_EMBED_RESOLVES.push(resolve);

            window.onYouTubeIframeAPIReady = () => {
                YT_IFRAME_API_READY = true;
                ALL_YOUTUBE_EMBED_RESOLVES.forEach((resolveToCall) => resolveToCall());
            };

            this.addScript(() => {
                return !!window.YT;
            }).finally(() => {
                if (YT_IFRAME_API_READY) {
                    resolve();
                }
            });
        });
    }
}
