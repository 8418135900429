












import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
  components: {},
})

export default class NewsCardComponent extends Vue {
  @Prop({}) private headline?: string;
  @Prop({}) private excerpt?: string;
  @Prop({}) private category?: string;
  @Prop({}) private link?: string;
  @Prop({}) private coverUrl?: string;
  @Prop({}) private lang?: string;

  private categoryClass : string = "news-item-card__badge--" + this.category;
  private i18nLink : string = "";

  private getI18nLink(): string {
    if (this.lang == "en") {
      return "en" + this.link as string;
    }
    return this.link as string;
  }
  private beforeMount() {
    this.i18nLink = this.getI18nLink();

  }
  private mounted() {
  }
}
