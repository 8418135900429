























import {Component, Prop, Vue} from 'vue-property-decorator';
import NewsCardComponent from "@/components/NewsCardComponent.vue";
import axios from "axios";
import Loader from "@/components/Loader.vue";

@Component({
  components: {Loader, NewsCardComponent},
})
export default class NewsOverviewComponent extends Vue {
  @Prop({default: 'corporate'}) private endpointName?: string;
  @Prop({}) private lang?: string;
  @Prop({default: true}) private isLoadingNews = true;
  @Prop({default: true}) private isLoadingCategories = true;
  @Prop({default: ''}) private filter = "";

  private data: any;
  private newsItems = [];
  private fetchNewsItems(cat?:string): void {
    let url = `${_mgnl.contextPath}/.rest/${this.endpointName}/newsitems/v1?lang=${this.lang}`;
    if(cat) {
      url = `${url}&category=${cat}`;
    }
    axios.get(url).then((response) => {
      this.newsItems = response.data.results;
      this.newsItems.sort((a, b) => (new Date(a['eventDate']) < new Date(b['eventDate'])) ? 1 : ((new Date(b['eventDate']) < new Date(a['eventDate'])) ? -1 : 0));
    }).catch(err => {
      console.error(err);
    }).finally(() => {
      this.isLoadingNews = false;
      this.isLoadingCategories = false;
    });
  }

  private filterNewsItems(e: Event) {
    this.isLoadingNews = true;
    this.fetchNewsItems(this.filter);
  }

  get categories(): [] {
    this.isLoadingCategories = true;
    let categories: [] = [];
    this.newsItems.map(newsItem => {
      // @ts-ignore
      if (!categories.includes(newsItem.category)) {
        // @ts-ignore
        categories.push(newsItem.category);
      };
    });
    this.isLoadingCategories = false;
    return categories;
  }

  private mounted() {
    this.fetchNewsItems();
  }
}
