/**
 * Get the best (in terms of resolution) default thumbnail for a YouTube video.
 * @param id The video id
 */
export default (id: string): Promise<string> => {
    /**
     * Not all YouTube video's will have a "maxresdefault" image (1280px wide),
     * but all video's will have an "hqdefault" image (480px wide).
     *
     * If there is no "maxresdefault" image for the given video id, YouTube will return a default grey image
     * that at the time of writing has a width of 120px and no status code errors.
     *
     * So, to make sure the "maxresdefault" image actually exists, and if not, to use the "hqdefault" as a default,
     * we need to check whether or not the "maxresdefault" image is wider than the "hqdefault" image.
     *
     * Given that there's a chance that YouTube might change the dimensions of the default fallback image or
     * for the "maxresdefault" image, checking for either width is a bit tricky.
     * Therefore we're using an — albeit arbitrary — width in the middle to check against.
     *
     */
    const MAX_RES_THRESHOLD = 800;
    const DEFAULT_URLS = {
        MAX_RES: `https://img.youtube.com/vi/${id}/maxresdefault.jpg`,
        HQ: `https://img.youtube.com/vi/${id}/hqdefault.jpg`,
    };

    return new Promise<string>((resolve) => {
        const img: HTMLImageElement = new Image();
        img.onload = () => {
            if (img.width >= MAX_RES_THRESHOLD) {
                resolve(DEFAULT_URLS.MAX_RES);
            } else {
                resolve(DEFAULT_URLS.HQ);
            }
        };
        img.onerror = () => {
            resolve(DEFAULT_URLS.HQ);
        };
        img.src = DEFAULT_URLS.MAX_RES;
    });
};
